<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                href="#"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Turmas</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Gerenciamento de
                  <span>Turmas</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-4">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de turmas
                          </h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                          <button
                            class="btn btn-sm btn-primary"
                            @click.prevent="showModal('modalCriarTurma')"
                          >
                            <small>+ Adicionar turma</small>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="Filtro por sigla da turma"
                                @keyup="filtroSiglaTurma"
                              >
                            </div>     
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                              <select
                                v-model="filtroCursoEscolhido"
                                class="form-control form-control-sm"
                                @change="aplicaFiltroTurmas()"
                              >
                                <option value="">
                                  -- Filtrar por curso --
                                </option>
                                <option
                                  v-for="(curso, index) in filtroCursos"
                                  :key="index"
                                  :value="curso"
                                >
                                  <span>{{ curso }}</span>
                                </option>
                              </select>
                            </div>  
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                              <select
                                v-model="filtroNivelEscolhido"
                                class="form-control form-control-sm"
                                @change="aplicaFiltroTurmas()"
                              >
                                <option value="">
                                  -- Filtrar por nível --
                                </option>
                                <option
                                  v-for="(nivel,
                                          index) in filtroNiveis"
                                  :key="index"
                                  :value="nivel"
                                >
                                  {{ retornaCursoNivel(nivel) }}
                                </option>
                              </select>
                            </div>                         
                            <div class="col-sm-12 col-md-12 col-lg-4 mb-1 d-none">
                              <select
                                v-model="filtroPlataformasEscolhido"
                                class="form-control form-control-sm"
                                @change="aplicaFiltroTurmas()"
                              >
                                <option value="">
                                  -- Filtrar por plataforma --
                                </option>
                                <option
                                  v-for="(plataforma,
                                          index) in filtroPlataformas"
                                  :key="index"
                                  :value="plataforma"
                                >
                                  {{ plataforma }}
                                </option>
                              </select>
                            </div>                            
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                              <select
                                v-model="filtroTurnosEscolhido"
                                class="form-control form-control-sm"
                                @change="aplicaFiltroTurmas()"
                              >
                                <option value="">
                                  -- Filtrar por turno --
                                </option>
                                <option
                                  v-for="(turno, index) in filtroTurnos"
                                  :key="index"
                                  :value="turno"
                                >
                                  <span v-if="turno == 1">Manhã</span>
                                  <span v-else-if="turno == 2">Tarde</span>
                                  <span v-else-if="turno == 3">Noite</span>
                                  <span v-else>Indefinido</span>
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 table-responsive mt-2">
                              <table class="table table-sm table-striped">
                                <thead class="thead-dark">
                                  <tr>
                                    <th>
                                      <small
                                        class="font-weight-bold"
                                      >Turma</small>
                                    </th> 
                                    <th>
                                      <small
                                        class="font-weight-bold"
                                      >Curso</small>
                                    </th>                         
                                    <th class="text-center">
                                      <small
                                        class="font-weight-bold"
                                      >Nível</small>
                                    </th>
                                    <th class="text-center">
                                      <small
                                        class="font-weight-bold"
                                      >Turno</small>
                                    </th>                                    
                                    <th class="text-center">
                                      <small
                                        class="font-weight-bold"
                                      >Ativo</small>
                                    </th>
                                    <th class="text-center">
                                      <small
                                        class="font-weight-bold"
                                      >Ações</small>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody v-if="fastPlataformaTurmaLoading">
                                  <tr>
                                    <td
                                      colspan="6"
                                      class="text-center"
                                      v-html="fastLoadingDados()"
                                    />
                                  </tr>
                                </tbody>
                                <tbody v-else-if="fastPlataformaTurmaFiltro.length">
                                  <tr
                                    v-for="turma in pageOfTurmas"
                                    :key="turma.id_turma"
                                  >
                                    <td class="align-middle">
                                      <small>{{ turma.sigla_turma }}</small>
                                    </td>  
                                    <td class="align-middle">
                                      <small>{{ turma.nome_curso }}</small>
                                    </td>                        
                                    <td class="text-center align-middle">
                                      <small>{{ retornaCursoNivel(turma.nivel) }}</small>
                                    </td>
                                    <td class="text-center align-middle">
                                      <small
                                        v-if="turma.turno == 1"
                                      >Manhã</small>
                                      <small
                                        v-else-if="turma.turno == 2"
                                      >Tarde</small>
                                      <small
                                        v-else-if="turma.turno == 3"
                                      >Noite</small>
                                      <small v-else>Indefinido</small>
                                    </td>                               
                                    <td class="text-center align-middle d-none">
                                      <div v-if="turma.data_final">
                                        <small
                                          v-if="
                                            new Date(
                                              turma.data_atual.replaceAll(
                                                '-',
                                                '/'
                                              )
                                            ) >
                                              new Date(
                                                turma.data_final
                                                  .split('T')[0]
                                                  .replaceAll('-', '/')
                                              )
                                          "
                                          class="text-danger"
                                        >
                                          {{
                                            new Date(turma.data_final)
                                          .toLocaleString()
                                          .split(" ")[0]
                                          }}
                                        </small>
                                        <small
                                          v-else
                                          class="text-info"
                                        >
                                        {{
                                            new Date(turma.data_final)
                                          .toLocaleString()
                                          .split(" ")[0]
                                          }}
                                        </small>
                                      </div>
                                      <div v-else>
                                        <small class="text-danger">Sem data</small>
                                      </div>
                                    </td>                                    
                                    <td class="text-center align-middle">                                      
                                      <small
                                        v-if="turma.ativo"
                                        class="text-success"
                                      >
                                        Sim <span v-if="turma.finalizada" class="text-danger">(finalizada)</span>                                        
                                      </small>
                                      <small
                                        v-else
                                        class="text-danger"
                                      >
                                        Não <span v-if="turma.finalizada" class="text-danger">(finalizada)</span>
                                      </small>
                                    </td>
                                    <td class="text-center align-middle">
                                      <a
                                        :href="
                                          '/plataforma/' +
                                            $route.params.id_plataforma +
                                            '/secretaria/turma/' +
                                            turma.id_turma
                                        "
                                        class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                        @click.prevent="$router.push('/plataforma/' +
                                          $route.params.id_plataforma +
                                          '/secretaria/turma/' +
                                          turma.id_turma)"
                                      >
                                        <small>Módulos
                                          <span class="badge badge-light">{{
                                            turma.total_programas
                                          }}</span>
                                        </small>
                                      </a>
                                      <a
                                        :href="
                                          '/plataforma/' +
                                            $route.params.id_plataforma +
                                            '/secretaria/turma/' +
                                            turma.id_turma
                                        "
                                        class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                        @click.prevent="$router.push('/plataforma/' +
                                          $route.params.id_plataforma +
                                          '/secretaria/turma/' +
                                          turma.id_turma + '/alunos')"
                                      >
                                        <small>Alunos <span class="badge badge-light">{{
                                            turma.total_alunos
                                          }}</span></small>
                                      </a>
                                      <button
                                        class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                        @click="exibeModalEditarTurma(turma)"
                                      >
                                        <small>Editar</small>
                                      </button>
                                      <span
                                        v-if="habilitaBotaoDeCheckout(turma)"
                                      >                                        
                                        <button
                                          class="btn btn-sm btn-info pt-0 pb-0 mr-2"
                                          @click="exibeModalConfiguraCheckout(turma)"
                                        >
                                          <small>Checkout</small>
                                        </button>
                                      </span>
                                      <button
                                      v-else
                                          v-tooltip.auto="{ content: exibeTooltip(habilitaBotaoDeCheckout(turma)) }"
                                          class="btn btn-sm btn-info pt-0 pb-0 mr-2"
                                          disabled
                                        >
                                          <small class="cursorConfig">Checkout</small>
                                        </button>
                                      <button
                                        v-if="turma.finalizada"
                                        class="btn btn-sm btn-primary pt-0 pb-0 mr-2 d-none"
                                        @click.prevent="exibeToasty('Recurso indisponível', 'error')"
                                      >
                                        <small>Relatórios</small>
                                      </button>
                                      <button
                                        v-else
                                        class="btn btn-sm btn-secondary pt-0 pb-0 mr-2 d-none"
                                        @click.prevent="exibeToasty('A turma deve ser finalizada', 'error')"
                                      >
                                        <small>Relatórios</small>
                                      </button>

                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td
                                      colspan="6"
                                      class="text-center"
                                    >
                                      Nenhuma turma cadastrada
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="col-12 text-center mt-4">
                              <Pagination
                                :page-size="10"
                                :items="fastPlataformaTurmaFiltro"
                                @changePage="pageOfTurmas = $event"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarTurma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Nova turma</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarTurma')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <label>Curso</label>
              <select
                v-model="fastTurmaNova.id_secretaria_curso"
                class="form-control"
              >
                <option value="">-- Selecione um curso --</option>
                <option
                  v-for="curso in fastSecretariaCursos"
                  :key="curso.id_secretaria_curso"
                  :value="curso.id_secretaria_curso"
                >
                  {{ curso.nome_curso }}
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label for>Sigla da turma</label>
              <input
                v-model="fastTurmaNova.sigla_turma"
                type="text"
                class="form-control"
                maxlength="30"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label for>Detalhes da Turma</label>
              <textarea
                v-model="fastTurmaNova.descricao_turma"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Turno</label>
              <select
                v-model="fastTurmaNova.turno"
                class="form-control"
              >
                <option value="0">
                  Indefinido
                </option>
                <option value="1">
                  Manhã
                </option>
                <option value="2">
                  Tarde
                </option>
                <option value="3">
                  Noite
                </option>
              </select>
            </div>           
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label for>Etapa</label>
              <input
                v-model="fastTurmaNova.etapa"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2 text-center">
              <label for="">Ativo</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-if="fastTurmaNova.ativo == 'S'"
                        v-model="fastTurmaNova.ativo"
                        checked="checked"
                        type="checkbox"
                      >
                      <input
                        v-else
                        v-model="fastTurmaNova.ativo"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarTurma()"
                >
                  Criar turma
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarTurma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar turma</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarTurma')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <label>Curso</label>
              <div
                v-for="curso in fastSecretariaCursos"
                :key="curso.id_secretaria_curso"
              >
                <input
                  v-if="curso.id_secretaria_curso == fastTurmaEditar.id_secretaria_curso"
                  type="text"
                  class="form-control"
                  :value="curso.nome_curso"
                  readonly
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label for>Sigla da turma</label>
              <input
                v-model="fastTurmaEditar.sigla_turma"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label for>Detalhes da Turma</label>
              <textarea
                v-model="fastTurmaEditar.descricao_turma"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Turno</label>
              <select
                v-model="fastTurmaEditar.turno"
                class="form-control"
              >
                <option value="0">
                  Indefinido
                </option>
                <option value="1">
                  Manhã
                </option>
                <option value="2">
                  Tarde
                </option>
                <option value="3">
                  Noite
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label for>Etapa</label>
              <input
                v-model="fastTurmaEditar.etapa"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2 text-center">
              <label for="">Ativo</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-if="fastTurmaEditar.ativo == 'S'"
                        v-model="fastTurmaEditar.ativo"
                        checked="checked"
                        type="checkbox"
                      >
                      <input
                        v-else
                        v-model="fastTurmaEditar.ativo"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <button
                    href="#"
                    type="button"
                    class="btn btn-primary"
                    @click="editarTurma()"
                  >
                    Confirmar alterações
                  </button>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                  <button
                      v-if="fastTurmaEditar.finalizada"
                      class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                      @click="exibeModalReabrirTurma(fastTurmaEditar)"
                    >
                      <small>Reabrir turma</small>
                    </button>
                    <button
                      v-else
                      class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                      @click="exibeModalFinalizarTurma(fastTurmaEditar)"
                    >
                      <small>Finalizar turma</small>
                    </button>
                  <button
                    class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                    @click="exibeModalExcluirTurma(fastTurmaEditar)"
                  >
                    <small>Excluir turma</small>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    
    <modal
      name="modalConfiguraCheckout"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-10 col-md-10 col-lg-10 pl-4 pr-4">
          <h4>Configurar links de checkout de pagamento para a turma {{fastTurmaEditar.sigla_turma}}</h4>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConfiguraCheckout')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div>
            <h5 class="font-weight-bolder">Lista de links configurados para a turma</h5>
            <div v-if="fastDadosDosLinksDeCheckoutDaTurma.length">
              <div v-for="data in fastDadosDosLinksDeCheckoutDaTurma" :key="data.id_checkout" class="d-flex justify-content-between my-1 py-1 border-bottom">
                <div v-if="data.habilitaEdicao" class="d-flex text-nowrap">
                  Link <select class="form-control form-control-sm mx-1" v-model="fastEditarLinkCheckout.ativo" ><option :value="true">Ativo</option><option :value="false">Inativo</option></select>,
                  no gateway <span class="font-weight-bold mx-1">{{ data.nome_gateway }}</span>, 
                  em <span class="font-weight-bold mx-1">{{ data.plano_pagamento.Tempo_determinado }}x</span>, 
                  via <span class="font-weight-bold mx-1">{{ data.tipo_pagamento.Nome_tipo_pagamento }}</span>, 
                  no valor de <input type="number" class="form-control form-control-sm mx-1" v-model="fastEditarLinkCheckout.valor_total">, 
                  válido até <input type="date" class="form-control form-control-sm mx-1" v-model="fastEditarLinkCheckout.data_vencimento">.
                  <a
                    class="mx-2"
                    href="#"
                    @click.prevent="editarLinkCheckout(data)"
                    v-tooltip.auto="{ content: exibeTooltip('salva_alteracoes') }"
                  >
                    <b-icon-check-square-fill variant="success" animation="throb" />
                  </a>
                  <a
                    class="mx-2"
                    href="#"
                    @click.prevent="data.habilitaEdicao = false"
                    v-tooltip.auto="{ content: exibeTooltip('cancela_alteracoes') }"
                  >
                    <b-icon-x-square-fill variant="danger" />
                  </a>
                </div>
                <div v-else>
                  Link <span v-if="data.ativo" class="font-weight-bold text-success">Ativo</span> <span v-else class="font-weight-bold text-danger">Inativo</span>,
                  no gateway <span class="font-weight-bold">{{ data.nome_gateway }}</span>, 
                  em <span class="font-weight-bold">{{ data.plano_pagamento.Tempo_determinado }}x</span>, 
                  via <span class="font-weight-bold">{{ data.tipo_pagamento.Nome_tipo_pagamento }}</span>, 
                  no valor de <span class="font-weight-bold">{{ data.valor_total_str }}</span>, 
                  válido até <span class="font-weight-bold">{{ new Date(data.data_vencimento).toLocaleDateString("pt-br") }}</span>.
                </div>
                <div>
                  <div class="text-nowrap">
                    <a class="btn btn-novo btn-info mx-1" :href="data.url_checkout" target="_blank">
                      <small>
                        Ver link (URL)
                      </small>
                    </a>
                    <button class="btn btn-novo btn-secondary mx-1" @click="habilitaEdicao(data)">
                      <small>
                        Editar
                      </small>
                    </button>
                    <button class="btn btn-novo btn-danger mx-1" @click="removerLinkCheckout(data)">
                      <small>
                        Remover
                      </small>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p class="text-danger text-center">Não existem links configurados para esta turma.</p>
            </div>
          </div>
          <div class="my-2">
            <h5 class="font-weight-bolder">Adicionar novo link para checkout</h5>
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <label class="d-flex flex-column mx-1">
                  <small class="d-flex">
                    <span
                      v-tooltip.auto="{ content: exibeTooltip('info_gateway') }"
                      class="mr-1"
                    >
                      <b-icon-question-circle />
                    </span>
                    Gateway
                  </small>
                  <select class="form-control" v-model="fastTurmaLinkCheckout.id_pagamento_gateway">
                    <option value="">Selecione</option>
                    <option v-for="g in this.fastOpcoesPagamento.gateways" :key="g.id_pagamento_gateway" :value="g.id_pagamento_gateway">{{ g.nome_gateway }}</option>
                  </select>
                </label>
                <label class="d-flex flex-column mx-1">
                  <small class="d-flex">
                    <span
                      v-tooltip.auto="{ content: exibeTooltip('info_vencimento_link') }"
                      class="mr-1"
                    >
                      <b-icon-question-circle />
                    </span>
                    Vencimento
                  </small>
                  <input type="date" class="form-control" v-model="fastTurmaLinkCheckout.data_vencimento_link">
                </label>
                <label class="d-flex flex-column mx-1">
                  <small class="d-flex">
                    <span
                      v-tooltip.auto="{ content: exibeTooltip('info_forma_pgto') }"
                      class="mr-1"
                    >
                      <b-icon-question-circle />
                    </span>
                    Forma
                  </small>
                  <select class="form-control" v-model="fastTurmaLinkCheckout.id_tipo_pagamento">
                    <option value="">Selecione</option>
                    <option v-for="t in this.fastOpcoesPagamento.tipos_pagamento" :key="t.Id_tipo_pagamento" :value="t.Id_tipo_pagamento">{{t.Nome_tipo_pagamento}}</option>
                  </select>
                </label>
                <label class="d-flex flex-column mx-1">
                  <small class="d-flex">
                    <span
                      v-tooltip.auto="{ content: exibeTooltip('info_plano_pgto') }"
                      class="mr-1"
                    >
                      <b-icon-question-circle />
                    </span>
                    Plano
                  </small>
                  <select class="form-control" v-model="fastTurmaLinkCheckout.id_plano_pagamento">
                    <option value="">Selecione</option>
                    <option v-for="p in this.fastOpcoesPagamento.planos_pagamento" :key="p.Id_plano_pagamento" :value="p.Id_plano_pagamento">{{ p.Nome_plano }}</option>
                  </select>
                </label>
                <label class="d-flex flex-column mx-1">
                  <small class="d-flex">
                    <span
                      v-tooltip.auto="{ content: exibeTooltip('info_descricao_link') }"
                      class="mr-1"
                    >
                      <b-icon-question-circle />
                    </span>
                    Descrição
                  </small>
                  <textarea class="form-control" placeholder="Descrição" rows="1" v-model="fastTurmaLinkCheckout.descricao_link"></textarea>
                </label>
                <label class="d-flex flex-column mx-1">
                  <small class="d-flex">
                    <span
                      v-tooltip.auto="{ content: exibeTooltip('info_valor') }"
                      class="mr-1"
                    >
                      <b-icon-question-circle />
                    </span>
                    Valor
                  </small>
                  <input type="number" class="form-control" placeholder="Valor Padrão" v-model="fastTurmaLinkCheckout.valor_total">
                </label>
                <label class="d-flex flex-column mx-1">
                  <small class="d-flex">
                    <span
                      v-tooltip.auto="{ content: exibeTooltip('nome_canal') }"
                      class="mr-1"
                    >
                      <b-icon-question-circle />
                    </span>
                    Nome do canal
                  </small>
                  <input type="text" class="form-control" placeholder="Mudar o nome" v-model="fastTurmaLinkCheckout.nome_canal">
                </label>
              </div>
              <div>
                <div d-flex align-items-center>
                  <button class="btn btn-novo btn-success align-bottom" @click="geraNovoLinkCheckout()">
                    <small>
                      Gerar link
                    </small>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>


    <modal
      name="modalExcluirTurma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Excluir turma?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirTurma')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="excluirTurma()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalFinalizarTurma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Finalizar turma?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalFinalizarTurma')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="finalizarTurma()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalReabrirTurma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Reabrir turma?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalReabrirTurma')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="reabrirTurma()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoSecretariaTurmas",
  components: {
    Pagination,
  },

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastPlataformaTurmaTotal: [],
      fastPlataformaTurmaFiltro: [],
      fastPlataformaTurmaLoading: true,
      pageOfTurmas: [],
      fastTurmaNova: {
        id_plataforma: 0,
        id_turma: "",
        sigla_turma: "",
        descricao_turma: "",
        turno: 0,
        etapa: "",
        ativo: "",
        id_secretaria_curso: 0,
        finalizada: false
      },
      fastTurmaEditar: {
        id_plataforma: 0,
        id_turma: "",
        sigla_turma: "",
        descricao_turma: "",
        turno: 0,
        etapa: "",
        ativo: "",
        id_secretaria_curso: 0,
        finalizada: false
      },
      // Checkout
      fastTurmaLinkCheckout: {
        id_plataforma: "",
        id_turma: "",
        id_pagamento_gateway: "",
        data_vencimento_link: "",
        descricao_link: "",
        valor_total: "",
        id_tipo_pagamento: "",
        id_plano_pagamento: "",
        nome_canal: "",
      },
      fastEditarLinkCheckout: {
        ativo: "",
        data_vencimento: "",
        valor_total: "",
      },
      fastExcluiCheckout: {
        id_turma_checkout: "",
        id_plataforma: "",
        id_turma: "",
      },
      fastOpcoesPagamento: {},
      fastDadosDosLinksDeCheckoutDaTurma: [],
      fastValidaBotaoCheckout: "",
      // Filtros
      filtroPlataformas: [],
      filtroTurnos: [],
      filtroPlataformasEscolhido: "",
      filtroTurnosEscolhido: "",
      filtroNiveis: [],
      filtroNivelEscolhido: "",
      filtroCursos: [],
      filtroCursoEscolhido: "",
      // Cursos
      fastSecretariaCursos: []
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getFastPlataformaTurmas(this.$route.params.id_plataforma);
            this.getSecretariaCursos(this.$route.params.id_plataforma);
            this.getOpcoesPagamentoCheckoutTurmas();
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    // Turmas
    async getFastPlataformaTurmas(id_plataforma) {
      this.fastPlataformaTurmaLoading = true
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_secretaria_turma/lista?id_plataforma=" +
            id_plataforma +
            "&id_turma=0",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          obj.forEach((e) => {
            e.ativo == "S" ? (e.ativo = true) : (e.ativo = false);            
            this.filtroPlataformas.push(e.nome_plataforma);
            this.filtroTurnos.push(e.turno);
            this.filtroNiveis.push(e.nivel);
            this.filtroCursos.push(e.nome_curso);

            if (e.data_final) {
              if (
                new Date(e.data_atual.replaceAll("-", "/")) >
                new Date(e.data_final.split("T")[0].replaceAll("-", "/"))
              )
                console.log("Finalizado");
              else console.log("Não finalizado");
            }
          });
          // Remove valores duplicados do array
          this.filtroPlataformas = this.filtroPlataformas.filter(
            (a, b) => this.filtroPlataformas.indexOf(a) === b
          );
          this.filtroTurnos = this.filtroTurnos.filter(
            (a, b) => this.filtroTurnos.indexOf(a) === b
          );
          this.filtroNiveis = this.filtroNiveis.filter(
            (a, b) => this.filtroNiveis.indexOf(a) === b
          );
          this.filtroCursos = this.filtroCursos.filter(
            (a, b) => this.filtroCursos.indexOf(a) === b
          );
          this.filtroCursos.sort();

          this.fastPlataformaTurmaTotal = obj;
          this.fastPlataformaTurmaFiltro = obj;

          console.log("this.fastPlataformaTurmaFiltro", this.fastPlataformaTurmaFiltro)
        } else {
          this.fastPlataformaTurmaTotal = [];
          this.fastPlataformaTurmaFiltro = [];
        }
        this.$store.state.fastCarregando = false;
        this.fastPlataformaTurmaLoading = false
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
        this.fastPlataformaTurmaLoading = false
      }
    },
    removeDuplicates(array) {
      return array.filter((a, b) => array.indexOf(a) === b);
    },
    async criarTurma() {
      console.log("this.fastTurmaNova", this.fastTurmaNova)
      let erros = [];
      if (!this.fastTurmaNova.id_secretaria_curso) erros.push("O curso é obrigatório é obrigatório");
      if (!this.fastTurmaNova.sigla_turma)
        erros.push("A sigla da turma é obrigatória");
      if (!this.fastTurmaNova.descricao_turma)
        erros.push("A descrição da turma é obrigatória");     

      if (erros.length) {
        erros.forEach((e) => this.exibeToasty(e, "error"));
      } else {
        this.fastTurmaNova.ativo = this.fastTurmaNova.ativo ? "S" : "N";
        const fast_secretaria_turma = {
          id_plataforma: this.$route.params.id_plataforma,
          sigla_turma: this.fastTurmaNova.sigla_turma,
          descricao_turma: this.fastTurmaNova.descricao_turma,
          turno: this.fastTurmaNova.turno,
          etapa: this.fastTurmaNova.etapa,
          ativo: this.fastTurmaNova.ativo,
          id_secretaria_curso: this.fastTurmaNova.id_secretaria_curso
        };

        this.$store.state.fastCarregando = true;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_secretaria_turma/insere",
            this.fastAjaxOptions("POST", JSON.stringify(fast_secretaria_turma))
          );

          let json = await resp.json();
          console.log("criarTurma", json);
          let obj = Array.from(json);
          this.$store.state.fastCarregando = false;
          this.getFastPlataformaTurmas(this.$route.params.id_plataforma);
          this.exibeToasty("Turma criada com sucesso", "success")
          this.fastTurmaNova = {
            id_plataforma: 0,
            id_turma: "",
            sigla_turma: "",
            descricao_turma: "",
            turno: "",
            etapa: "",
            ativo: "",
            id_secretaria_curso: 0
          };
          this.hideModal("modalCriarTurma");
        } catch (e) {
          this.$store.state.fastCarregando = false;
          console.log("Erro", e);
        }
      }
    },
    exibeModalEditarTurma(turma) {
      this.fastTurmaEditar = turma;
      this.showModal("modalEditarTurma");
    },
    async getOpcoesPagamentoCheckoutTurmas(){
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            `api/fast_secretaria_turma_checkout/lista_opcoes_pagamento?id_plataforma=${this.$route.params.id_plataforma}`,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        this.fastOpcoesPagamento = json                
      } catch (error) {
        console.log(error);
        this.exibeToasty(error, "error")
      }
    },
    async getDadosDosLinksDeCheckoutDaTurma(id_turma){
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            `api/fast_secretaria_turma_checkout/lista?id_plataforma=${this.$route.params.id_plataforma}&id_turma=${id_turma}`,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        this.fastDadosDosLinksDeCheckoutDaTurma = obj
        this.fastDadosDosLinksDeCheckoutDaTurma = this.fastDadosDosLinksDeCheckoutDaTurma.map(item => ({
          ...item,
          habilitaEdicao: false
        }));
        // console.log("this.fastDadosDosLinksDeCheckoutDaTurma", this.fastDadosDosLinksDeCheckoutDaTurma);
        
      } catch (error) {
        console.log(error);
        this.exibeToasty(error, "error")
      }
    },
    habilitaBotaoDeCheckout(turma){
      if (turma && this.fastSecretariaCursos.length) {        
        const curso_info = this.fastSecretariaCursos.find(c => c.id_secretaria_curso === turma.id_secretaria_curso)
        return !curso_info.loja_virtual || turma.finalizada || !turma.ativo ? false : true;
      }
    },
    exibeModalConfiguraCheckout(turma) {
      this.fastTurmaEditar = turma;
      this.getDadosDosLinksDeCheckoutDaTurma(turma.id_turma)
      this.showModal("modalConfiguraCheckout");
    },
    async geraNovoLinkCheckout(){
      let obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_turma: this.fastTurmaEditar.id_turma,
        id_pagamento_gateway: this.fastTurmaLinkCheckout.id_pagamento_gateway,
        data_vencimento_link: this.fastTurmaLinkCheckout.data_vencimento_link,
        descricao_link: this.fastTurmaLinkCheckout.descricao_link,
        valor_total: this.fastTurmaLinkCheckout.valor_total ? +this.fastTurmaLinkCheckout.valor_total : null,
        id_tipo_pagamento: this.fastTurmaLinkCheckout.id_tipo_pagamento,
        id_plano_pagamento: this.fastTurmaLinkCheckout.id_plano_pagamento,
        nome_canal_solicitacao_matricula: this.fastTurmaLinkCheckout.nome_canal
      }
      try {
        let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_turma_checkout/gera_link_checkout",
            this.fastAjaxOptions("POST", JSON.stringify(obj))
          );
        let json = await resp.json();
        // console.log("json", json);
        if (json.error) {
          this.exibeToasty(json.error, "error")
        }
        
        this.limpaCamposCheckout()
        this.getDadosDosLinksDeCheckoutDaTurma(this.fastTurmaEditar.id_turma)
      } catch (error) {
        console.error(error);
        this.exibeToasty(error, "error")
      }
    },
    habilitaEdicao(data) {
      this.fastDadosDosLinksDeCheckoutDaTurma = this.fastDadosDosLinksDeCheckoutDaTurma.map(item => {
        if (item.id_checkout === data.id_checkout) {
          return { ...item, habilitaEdicao: !item.habilitaEdicao };
        }
        return item;
      });
    },
    async editarLinkCheckout(data) {
      // console.log("datatstesteste",data);
      
      let obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_turma: data.id_turma,
        id_checkout: data.id_checkout,
        url_checkout: data.url_checkout,
        id_pagamento_gateway: data.id_pagamento_gateway,
        id_turma_checkout: data.id_turma_checkout,
        id_tipo_pagamento: data.tipo_pagamento.Id_tipo_pagamento,
        id_plano_pagamento: data.plano_pagamento.Id_plano_pagamento,
        ativo: this.fastEditarLinkCheckout.ativo,
        data_vencimento: this.fastEditarLinkCheckout.data_vencimento,
        valor_total: +this.fastEditarLinkCheckout.valor_total,
      }
      // console.log("obj", obj);
      try {
        let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_turma_checkout/atualiza",
            this.fastAjaxOptions("POST", JSON.stringify(obj))
          );
        let json = await resp.json();
        // console.log("json", json);
        data.habilitaEdicao = false
        this.getDadosDosLinksDeCheckoutDaTurma(this.fastTurmaEditar.id_turma)
      } catch (error) {
        console.error(error);
        this.exibeToasty(error, "error")
      }
    },
    async removerLinkCheckout(data){
      let obj = {
        id_turma_checkout: data.id_turma_checkout,
        id_plataforma: this.$route.params.id_plataforma,
        id_turma: data.id_turma,
      }
      try {
        let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_turma_checkout/exclui",
            this.fastAjaxOptions("POST", JSON.stringify(obj))
          );
        let json = await resp.json();
        // console.log("json", json);
        this.getDadosDosLinksDeCheckoutDaTurma(this.fastTurmaEditar.id_turma)
      } catch (error) {
        console.error(error)
        this.exibeToasty(error, "error")
      }
    },
    limpaCamposCheckout(){
      this.fastTurmaLinkCheckout.id_pagamento_gateway = ""
      this.fastTurmaLinkCheckout.data_vencimento_link = ""
      this.fastTurmaLinkCheckout.descricao_link = ""
      this.fastTurmaLinkCheckout.valor_total = ""
      this.fastTurmaLinkCheckout.id_tipo_pagamento = ""
      this.fastTurmaLinkCheckout.id_plano_pagamento = ""
      this.fastTurmaLinkCheckout.nome_canal = ""
    },
    async editarTurma() {
      let erros = [];
      if (!this.fastTurmaEditar.id_secretaria_curso) erros.push("O curso é obrigatório");
      if (!this.fastTurmaEditar.sigla_turma)
        erros.push("A sigla da turma é obrigatória");
      if (!this.fastTurmaEditar.descricao_turma)
        erros.push("A descrição da turma é obrigatória");     

      if (erros.length) {
        erros.forEach((e) => this.exibeToasty(e, "error"));
      } else {
        this.fastTurmaEditar.ativo = this.fastTurmaEditar.ativo ? "S" : "N";
        const fast_secretaria_turma = {
          id_turma: this.fastTurmaEditar.id_turma,
          id_plataforma: this.$route.params.id_plataforma,
          sigla_turma: this.fastTurmaEditar.sigla_turma,
          descricao_turma: this.fastTurmaEditar.descricao_turma,
          turno: this.fastTurmaEditar.turno,
          etapa: this.fastTurmaEditar.etapa,
          ativo: this.fastTurmaEditar.ativo,
          id_secretaria_curso: this.fastTurmaEditar.id_secretaria_curso
        };

        this.$store.state.fastCarregando = true;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_secretaria_turma/atualiza",
            this.fastAjaxOptions("POST", JSON.stringify(fast_secretaria_turma))
          );

          let json = await resp.json();
          console.log("editarTurma", json);
          let obj = Array.from(json);
          this.$store.state.fastCarregando = false;
          this.getFastPlataformaTurmas(this.$route.params.id_plataforma);
          this.exibeToasty("Turma editada com sucesso", "success")
          this.fastTurmaNova = {
            id_plataforma: 0,
            id_turma: "",
            sigla_turma: "",
            descricao_turma: "",
            turno: "",
            etapa: "",
            ativo: "",
            id_secretaria_curso: 0
          };
          this.hideModal("modalEditarTurma");
        } catch (e) {
          this.$store.state.fastCarregando = false;
          console.log("Erro", e);
        }
      }
    },
    exibeModalExcluirTurma(turma) {
      this.fastTurmaEditar = turma;
      this.showModal("modalExcluirTurma");
    },
    async excluirTurma() {
      const fast_secretaria_turma = {
        id_plataforma: this.$route.params.id_plataforma,
        id_turma: this.fastTurmaEditar.id_turma,
        id_secretaria_curso: this.fastTurmaEditar.id_secretaria_curso
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_turma/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(fast_secretaria_turma))
        );
        let json = await resp.json();
        let obj = Array.from(json);
        this.getFastPlataformaTurmas(this.$route.params.id_plataforma);
        this.exibeToasty("Turma excluída com sucesso", "success")
        this.hideModal("modalEditarTurma");
        this.hideModal("modalExcluirTurma");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalFinalizarTurma(turma) {
      this.fastTurmaEditar = turma;
      this.showModal("modalFinalizarTurma");
    },
    async finalizarTurma() {
      const fast_secretaria_turma = {
        id_plataforma: this.$route.params.id_plataforma,
        id_turma: this.fastTurmaEditar.id_turma,
        id_secretaria_curso: this.fastTurmaEditar.id_secretaria_curso
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_turma/finaliza",
          this.fastAjaxOptions("POST", JSON.stringify(fast_secretaria_turma))
        );
        let json = await resp.json();
        let obj = Array.from(json);
        this.getFastPlataformaTurmas(this.$route.params.id_plataforma);
        this.exibeToasty("Turma finalizada com sucesso", "success")
        this.hideModal("modalFinalizarTurma");
        this.hideModal("modalEditarTurma");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalReabrirTurma(turma) {
      this.fastTurmaEditar = turma;
      this.showModal("modalReabrirTurma");
    },
    async reabrirTurma() {
      const fast_secretaria_turma = {
        id_plataforma: this.$route.params.id_plataforma,
        id_turma: this.fastTurmaEditar.id_turma,
        id_secretaria_curso: this.fastTurmaEditar.id_secretaria_curso
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_turma/reabrir",
          this.fastAjaxOptions("POST", JSON.stringify(fast_secretaria_turma))
        );
        let json = await resp.json();
        let obj = Array.from(json);
        this.getFastPlataformaTurmas(this.$route.params.id_plataforma);
        this.exibeToasty("Turma reaberta com sucesso", "success")
        this.hideModal("modalReabrirTurma");
        this.hideModal("modalEditarTurma");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    filtroSiglaTurma(e) {
      let text = e.target.value;
      this.fastPlataformaTurmaFiltro = this.fastPlataformaTurmaTotal.filter(
        (e) => {
          return (
            e.sigla_turma.toLowerCase().indexOf(text.toLowerCase()) != "-1"
          );
        }
      );
    },
    aplicaFiltroTurmas() {
      this.fastPlataformaTurmaFiltro = this.fastPlataformaTurmaTotal.filter(
        (e) => {
          if (this.filtroPlataformasEscolhido) {
            return (
              e.nome_plataforma
                .toLowerCase()
                .indexOf(this.filtroPlataformasEscolhido.toLowerCase()) != "-1"
            );
          } else {
            return true;
          }
        }
      );     
      
      this.fastPlataformaTurmaFiltro = this.fastPlataformaTurmaFiltro.filter(
        (e) => {
          if (this.filtroCursoEscolhido) {
            return (
              e.nome_curso
                .toLowerCase()
                .indexOf(this.filtroCursoEscolhido.toLowerCase()) != "-1"
            );
          } else {
            return true;
          }
        }
      );  

      this.fastPlataformaTurmaFiltro = this.fastPlataformaTurmaFiltro.filter(
        (e) => {
          if (this.filtroTurnosEscolhido) {
            return e.turno == this.filtroTurnosEscolhido;
          } else {
            return true;
          }
        }
      );
      

      this.fastPlataformaTurmaFiltro = this.fastPlataformaTurmaFiltro.filter(
        (e) => {
          if (this.filtroNivelEscolhido) {
            return e.nivel == this.filtroNivelEscolhido;
          } else {
            return true;
          }
        }
      );
      
      this.fastPlataformaTurmaFiltro = this.fastPlataformaTurmaFiltro.filter(
        (e) => {
          if (this.filtroCursoEscolhido) {
            return (
              e.nome_curso
                .toLowerCase()
                .indexOf(this.filtroCursoEscolhido.toLowerCase()) != "-1"
            );
          } else {
            return true;
          }
        }
      ); 
      
    },
    // Cursos
    getSecretariaCursos(id_plataforma){
      this.promiseGetFastApi("api/fast_secretaria_curso/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=0").then(obj => {
        console.log("getSecretariaCursos", obj);
        this.$store.state.fastCarregando = false;
        if (obj.length) {
          obj = obj.filter(e => { if (e.publicado) return true })
          this.fastSecretariaCursos = obj;
        }
          else
          this.fastSecretariaCursos = [];
      }).catch(e => {
        console.log(e);
      })
    },
    retornaCursoNivel(nivel) {
      switch (nivel) {
        case 'F':
          return 'Ensino fundamental'
          break;
        case 'E':
          return 'Ensino médio'
          break;
        case 'J':
          return 'EJA'
          break;
        case 'T':
          return 'Técnico'
          break;        
        case 'S':
          return 'Ensino superior'
          break;
        case 'P':
          return 'Pós-graduação'
          break;
        case 'M':
          return 'Mestrado'
          break;
        case 'D':
          return 'Doutorado'
          break;        
        default:
          break;
      }
    },
    exibeTooltip(tipo) {
      switch (tipo) {
        case false:
          return (
            '<p>Para habilitar o checkout, o respectivo curso deve estar com a opção "Disponível na Loja Virtual", a turma deve estar ativa e não pode estar finalizada.</p>'
          );
        case "info_gateway":
          return (
            '<p>Especifique o gateway de pagamentos que será utilizado para gerar a cobrança.</p>'          
          );
        case "info_vencimento_link":
          return (
            '<p>Especifique uma data de vencimento para o link gerado, ou seja, o link irá funcionar até a data especificada.</p>'          
          );
        case "info_forma_pgto":
          return (
            '<p>Especifique com qual método de pagamento o link gerado irá operar, os métodos listados aqui são criados na plataforma FastEAD e devem corresponder a um método váido no gateway de pagamentos.</p>'          
          );
        case "info_plano_pgto":
          return (
            '<p>Especifique com qual plano de pagamento o link gerado irá operar, por exemplo, caso seja selecionada a forma "Cartão de Crédito" e plano de pagamento de "Parcelado em 12x", o link gerado irá fornecer opções de pagamento de até 12x.</p>' + 
            '<p>Os métodos listados aqui são criados na plataforma FastEAD e devem corresponder a um método váido no gateway de pagamentos, cada número de parcelas deve ter um plano de pagamento correspondente cadastrado no FastEAD.</p>'          
          );
        case "info_descricao_link":
          return (
            '<p>Insira uma descrição que será exibida na tela de checkout.</p>'          
          );
        case "info_valor":
          return (
            '<p>Informe um valor em R$(reais brasileiros) personalizado para cobrança neste link. Caso não seja passado um valor específico, o link irá ser gerado considerando o valor padrão do curso ao qual essa turma está vinculada.</p>'          
          );
        case "nome_canal":
          return (
            '<p>Esse nome ficará vinculado às matrículas efetuadas quando a compra for proveniente deste link. Por padrão, o nome do canal é LINK_CHECKOUT.</p>'          
          );
        case "salva_alteracoes":
          return (
            '<p>Salvar alterações.</p>'          
          );
        case "cancela_alteracoes":
          return (
            '<p>Cancelar alterações.</p>'          
          );
        default:
          break;
      }
    },
  },
};
</script>

<style scope>
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}

/* Tooltip */
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: #fff;
  color: #000;
  border-radius: 16px;
  padding: 5px 10px 4px;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  width: 500px;
  min-width: 500px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
